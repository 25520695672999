<template>
    <client-page>
        
        <sub-visual
        sh="사업분야"
        sc="에프원시큐리티는 전문가들로 이루어져 있습니다."
        tabActive="솔루션 사업"
        icon="/images/sub/visual/sv2-icon.svg"
        bg="/images/sub/visual/sv2.png">
        </sub-visual>

        <section class="sub-section">
            <v-container>
                <v-row class="row--xx-small">
                    <v-col cols="12" lg="5" xl="4" data-aos="fade-right">
                        <h4 class="sub-tit--md font-weight-bold">
                            솔루션 사업
                        </h4>
                    </v-col>
                    <v-col cols="12" lg="7" xl="8" data-aos="fade-left">
                        <p class="sub-tit--sm color-gray line-height-16">
                            솔루션 사업부는 UWSS, WEBCASTLE, WSFINDER, WMDS 등 소프트웨어개발, <br class="d-none d-xl-block">
                            솔루션 영업을 주요업무로 하며, 제품의 설치 및 안내, A/S 서비스, 주기적인 업데이트 등 <br class="d-none d-xl-block">
                            전반적인 사업을 담당합니다. <br>
                            또한, 일본, 베트남지사와 연계하여 솔루션을 수출하고, 해외 전시 참여 및 홍보 등을 <br class="d-none d-xl-block">
                            진행합니다. 
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="sub-section bg-grey mb-110 mb-md-140 mb-lg-180">
            <v-container>
                <v-img class="d-none d-md-block mx-auto" max-width="1030" src="/images/sub/business/solution/solution-img.png" data-aos="fade"></v-img>
                <v-img class="d-block d-md-none mx-auto" max-width="670" src="/images/sub/business/solution/solution-img-mo.png" data-aos="fade"></v-img>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
</style>